import React from 'react'

import GeneralLayout from '../components/layouts/general'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <GeneralLayout>
    <SEO title="404: Not found" canonical="404" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </GeneralLayout>
)

export default NotFoundPage
